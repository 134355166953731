import tooltipDirective from "@/directives/Tooltip/";
import { App } from 'vue';


// register all directives
const directives = (app: App) => {
    tooltipDirective(app);
};

export default directives;
