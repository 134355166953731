import axios from "axios";
import store from '@/store';

const headers: { Authorization?: string, "Content-type": string } = {
    "Content-type": "application/json",
};

export const TraffixApi = axios.create({
    baseURL: `${process.env.VUE_APP_TRAFFIX_URL}`,
    headers: headers,
});

TraffixApi.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401 || error.response.status === 403) {
            store.dispatch('logout');
        }
        return Promise.reject(error)
    }
)