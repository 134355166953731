import { TraffixApi } from '@/api/TraffixApi';
import { createStore } from 'vuex'
import router from '@/router';

export default createStore({
    state: {
        user: null
    },

    mutations: {
        setUserData(state, userData) {
            state.user = userData
            localStorage.setItem('userToken', JSON.stringify(userData))
            TraffixApi.defaults.headers.common.Authorization = `Bearer ${userData.token}`
        },

        clearUserData(state) {
            localStorage.removeItem('userToken');
            state.user = null;
            router.push({
                path: '/login',
            });
        }
    },

    actions: {
        login({ commit }, userData) {
            commit('setUserData', userData);
            const path = sessionStorage.getItem('redirectPath') || '/dashboard';
            sessionStorage.removeItem('redirectPath');
            router.push({
                path: path,
            })
        },

        logout({ commit }) {
            commit('clearUserData')
        }
    },

    getters: {
        isLoggedIn: state => !!state.user
    }
})
