import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import directives from "./directives/";
import "@/styles/main.scss";

const app = createApp(App);
directives(app);

app.use(router)
    .use(store)
    .mount('#app');

