import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginView.vue'),
    },
    {
        path: '/subscribe',
        name: 'subscribe',
        component: () => import('@/views/SubscribeView.vue'),
    },
    {
        path: '/connect-hubspot',
        name: 'connect-hubspot',
        component: () => import('@/views/ConnectHubspotView.vue'),
        props: (route) => ({ code: route.query.code, state: route.query.state }),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/thankyou',
        name: 'thankyou',
        component: () => import('@/views/ThankYouView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: () => import('@/views/WelcomeView.vue'),
    },
    {
        path: '/connect-afas',
        name: 'connect-afas',
        component: () => import('@/views/ConnectAfasView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/DashboardView.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('@/views/HelpView.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        path: '/get-fields',
        name: 'get-fields',
        component: () => import('@/views/AfasHubspotFieldMapper.vue'),
        meta: {
            requiresAuth: true
        },
        props: (route) => ({ token: route.query.token }),
    },
    {
        path: '/forgotten-password',
        name: 'forgotten-password',
        component: () => import('@/views/ForgottenPasswordView.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn) {
            sessionStorage.setItem('redirectPath', to.path)
            next({ path: '/login' })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
